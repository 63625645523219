var phoneReg =
  /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
const validatePhone = (rules, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号"));
  } else {
    if (!phoneReg.test(value)) {
      callback(new Error("手机号格式不正确"));
    } else {
      callback();
    }
  }
};

const normal = (message, trigger = "blur") => {
  return [{ required: true, message: message, trigger: trigger }];
  // 通用input必填验证
};
const selectNormal = (message, trigger = "change") => {
  return [{ required: true, message: message, trigger: trigger }];
  // 通用选择框必填验证
};
const img = (that, feild, message) => {
  const checkImages = (rule, value, callback) => {
    if (!that.form[feild]) {
      callback(new Error(message));
    } else {
      callback();
    }
  };
  return [
    {
      required: true,
      validator: checkImages,
      trigger: "blur",
    },
  ];
  // 验证单张图片
};
const checkNum = (rule, value, callback) => {
  let reg = /^[0-9]*$/;
  if (!reg.test(value)) {
    callback(new Error(`请输入数字`));
  } else {
    callback();
  }
};
const checknumber = (message) => {
  return [
    { required: true, message: message, trigger: "blur" },
    { validator: checkNum, trigger: "blur" },
  ];
};

const range = (message, number1, number2) => {
  const rangeValid = (rule, value, callback) => {
    let reg = /^[0-9]*$/;
    if (!reg.test(value)) {
      if (value > number2) {
        callback(new Error(`请输入小于或等于${number2}的整数`));
      } else if (value < number1) {
        callback(new Error(`请输入小于或等于${number1}的整数`));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  return [
    { required: true, message: message, trigger: "blur" },
    {
      validator: rangeValid,
      message: `数字范围在${number1} 到 ${number2} `,
      trigger: "blur",
    },
  ];
};
export default {
  phone: [{ required: true, validator: validatePhone, trigger: "blur" }], // 2021正则手机号element表单验证规则
  normal: normal,
  selectNormal: selectNormal,
  img: img,
  number: checknumber,
  range: range,
};
