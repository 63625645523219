<template>
  <div v-loading="loading" class="student">
    <div class="operate">
      <div class="filter">
        <div class="row">
          <div class="label">学段：</div>
          <el-select
            v-model="search.level"
            clearable
            placeholder="请选择"
            @change="levelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">入学年份：</div>
          <el-select
            v-model="search.year"
            placeholder="请选择"
            clearable
            @change="getData"
          >
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">班级层次：</div>
          <el-select
            v-model="search.typeName"
            placeholder="请选择"
            clearable
            @change="getData"
          >
            <el-option
              v-for="item in classLevelList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="row">
          <el-input
            v-model="search.studentName"
            placeholder="请输入关键字"
          ></el-input>
        </div> -->
      </div>
      <div class="operate-btn">
        <el-button type="primary" plain @click="classLevel">班级层次</el-button>
        <!-- <el-button type="primary" plain @click="batchEdit">批量修改</el-button> -->
        <!-- <el-button type="primary" plain @click="add">新增</el-button> -->
        <!-- <el-button type="success" plain @click="importExcel">导入</el-button>
        <el-button type="success" plain @click="exportExcel">导出</el-button> -->
        <!-- <el-button type="danger" plain @click="clearStudent"
          >清除班级</el-button
        > -->
      </div>
    </div>
    <div class="tableList">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55" align="center">
        </el-table-column> -->
        <el-table-column width="200" align="center" label="学段">
          <template slot-scope="{ row }">
            <span v-if="row.level == 2">初中</span>
            <span v-if="row.level == 3">高中</span>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          align="center"
          prop="year"
          label="入学年份"
        >
        </el-table-column>
        <el-table-column
          v-for="item in labels"
          :key="item.sort"
          :prop="item.prop"
          :width="item.width ? item.width : 'auto'"
          :label="item.label"
          align="center"
        >
          <el-table-column
            v-if="item.prop === 'firstClass'"
            prop="firstSubjectName"
            label="学科"
            align="center"
          />
          <el-table-column
            v-if="item.prop === 'firstClass'"
            prop="firstClassNum"
            label="教学班"
            align="center"
          />

          <el-table-column
            v-if="item.prop === 'secondClass'"
            prop="secondSubject"
            label="学科"
            align="center"
          />
          <el-table-column
            v-if="item.prop === 'secondClass'"
            prop="secondClassNum"
            label="教学班"
            align="center"
          />

          <el-table-column
            v-if="item.prop === 'thirdClass'"
            prop="thirdSubjectName"
            label="学科"
            align="center"
          />
          <el-table-column
            v-if="item.prop === 'thirdClass'"
            prop="thirdClassNum"
            label="教学班"
            align="center"
          />
          <template v-if="item.type == 'normal'" slot-scope="{ row }">
            <div v-if="item.prop === 'type'">
              <span v-if="row.type === 1">火箭班</span>
              <span v-if="row.type === 2">基础班</span>
              <span v-if="row.type === 3">平行班</span>
            </div>
            <div v-else-if="item.prop === 'level'">
              <span v-if="row.level === 2">初中</span>
              <span v-if="row.level === 3">高中</span>
            </div>
            <span v-else>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :key="'labelList99'"
          label="操作"
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="edit(row)">修改</el-button>
            <!-- <el-button type="text" @click="del(row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <footer-tab
      v-if="showTab"
      :multiple-selection="multipleSelection"
      @delete="deleteStudent"
      @hidden="hidden"
    />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div v-if="title === '清除'">
        <div class="filter">
          <div class="row">
            <span>学段：</span>
            <el-select
              v-model="clear.level"
              style="width: 112px"
              placeholder="请选择"
              @change="getClearClassNum"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <span>入学年份：</span>
            <el-date-picker
              v-model="clear.year"
              type="year"
              value-format="yyyy"
              placeholder="入学年份"
              @change="getClearClassNum"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="classList">
          <div class="row">
            <span>班级：</span>
            <el-button type="text" @click="allCheckChange">全选</el-button>
            <el-button type="text" @click="reverseChange">反选</el-button>
            <el-button type="text" @click="cancleAllChange">取消全部</el-button>
          </div>
          <div class="checkbox">
            <el-checkbox-group
              v-model="checkedClass"
              @change="handleCheckedChange"
            >
              <el-checkbox
                v-for="(item, index) in classList"
                :key="index"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div v-if="title === '批量修改'" class="batch">
        <div class="text">温馨提示：</div>
        <div class="text">
          1、批量修改学生将修改除考号以外的所有学生属性信息（包括：学籍号、姓名、学年、班级、分类）。
        </div>
        <div class="text">
          2、请先使用导出功能导出需要修改的学生，然后在Excel中修改学生信息，使用批量修改功能导入Excel修改。
        </div>
        <div class="template">
          文件上传：
          <el-link
            type="primary"
            href="https://static.wtjy.com/analy/template/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?id=2"
            >学生信息模版下载<i class="el-icon-download"></i
          ></el-link>
        </div>
      </div>
      <div v-if="title === '批量导入'" class="batch">
        <div class="text">温馨提示：</div>
        <div class="text">1、批量导入学生时，避免01、02等显示方式</div>
        <div class="text">2、导入时避免学生信息重复</div>
        <div class="template">
          文件上传：
          <el-link
            type="primary"
            href="https://static.wtjy.com/analy/template/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?id=2"
            >学生信息模版下载<i class="el-icon-download"></i
          ></el-link>
        </div>
      </div>
      <div v-if="title === '班级层次'">
        <div
          v-for="(item, index) in classLevelData"
          :key="index"
          class="levelRow"
        >
          <el-input
            v-model="item.name"
            style="width: 50%; margin-right: 10px"
          ></el-input>
          <el-button type="primary" plain @click="addLevel">添加</el-button
          ><el-button type="primary" plain @click="delLevel(index)"
            >删除</el-button
          >
        </div>
      </div>
      <el-upload
        v-if="title === '批量修改' || title === '批量导入'"
        ref="upload"
        class="upload-demo"
        name="file"
        drag
        :action="uploadAvatarURL"
        :headers="uploadAvatarHeaders"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-success="handleOnSuccess"
        :auto-upload="false"
        :data="{ type: type }"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
      </el-upload>
      <el-form
        v-if="title === '新增' || title === '编辑'"
        ref="form"
        label-position="right"
        label-width="100px"
        :rules="rules"
        :model="form"
      >
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="学段" prop="level">
              <el-select
                v-model="form.level"
                style="width: 220px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入学年份" prop="year">
              <el-date-picker
                v-model="form.year"
                type="year"
                value-format="yyyy"
                placeholder="入学年份"
                @change="getData"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item label="班级" prop="classNum">
              <el-input
                v-model="form.classNum"
                style="width: 220px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="班级层次" prop="typeName">
              <el-select v-model="form.typeName" placeholder="请选择">
                <el-option
                  v-for="item in classLevelList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          v-if="title === '清除'"
          v-loading="submitLoading"
          type="danger"
          @click="clearAll"
          >清除</el-button
        >
        <el-button
          v-if="title === '批量导入'"
          v-loading="submitLoading"
          type="primary"
          @click="importSubmit"
          >导入</el-button
        >
        <el-button
          v-if="title === '批量修改'"
          v-loading="submitLoading"
          type="primary"
          @click="importSubmit"
          >批量修改</el-button
        >
        <el-button
          v-if="title === '新增' || title === '编辑'"
          v-loading="submitLoading"
          type="primary"
          @click="submit"
          >确 定</el-button
        >
        <el-button
          v-if="title === '班级层次'"
          v-loading="submitLoading"
          type="primary"
          @click="saveClassLevel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import footerTab from "./component/footerTab.vue";
import { getStore } from "@/core/util/store";
import {
  getClassNum,
  getClassNumPage,
  delStudentNewExam,
  addschoolgradeclass,
  exportstudent,
  cleanStudent,
  classlistbylevelandyear,
  removeStudent,
  classlevel,
  editSchoolgradeclass,
  saveClasslevel,
} from "@/core/api/newOther";
import { electiveCourse } from "@/core/util/constdata";
import { fileDownloadByUrl } from "@/core/util/util";
import validate from "@/core/util/formValidate";
export default {
  name: "NewOtherStudent",
  components: {
    footerTab,
  },
  data() {
    const NumberAndLetter = /^(\w|[\u4E00-\u9FA5])*$/;
    const password = new RegExp("[\\u4E00-\\u9FFF]+", "g"); // 不是中文
    const phone =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    const validateUsername = (rule, value, callback) => {
      if (value) {
        if (!NumberAndLetter.test(value)) {
          callback(new Error("账号格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validateStudentNo = (rule, value, callback) => {
      if (value) {
        if (!NumberAndLetter.test(value)) {
          callback(new Error("学籍号格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value) {
        if (password.test(value)) {
          callback(new Error("密码格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (value) {
        if (!phone.test(value)) {
          callback(new Error("手机号码格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearList = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    return {
      classLevelData: [{ name: "" }],
      levelOptions: [
        {
          value: 2,
          label: "初中",
        },
        {
          value: 3,
          label: "高中",
        },
      ],
      electiveCourse: electiveCourse,
      uploadAvatarHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": userMsg.content.schoolId,
      },
      dialogVisible: false,
      fileList: [],
      uploadAvatarURL: "/cms/schoolstudent/import",
      type: "", // 1:批量新增  2:批量修改
      rules: {
        name: validate.normal("请输入学生姓名"),
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        studentNo: [{ validator: validateStudentNo, trigger: "blur" }],
        firstSubject: [
          { required: true, message: "请选择选考科目1", trigger: "change" },
        ],
        secondSubject: [
          { required: true, message: "请选择选考科目2", trigger: "change" },
        ],
        thirdSubject: [
          { required: true, message: "请选择选考科目3", trigger: "change" },
        ],
      },
      form: {},
      showTab: false,
      checkList: [],
      labels: [],
      title: "",

      search: {
        level: "",
      },
      page: {
        current: 1,
        size: 50,
      },
      tableData: [],
      labelList: [
        {
          label: "班级",
          prop: "name",
          type: "normal",
          sort: 1,
        },

        {
          label: "班级层次",
          prop: "typeName",
          width: 500,
          type: "normal",
          sort: 4,
        },
      ],
      total: 0,
      multipleSelection: [],
      loading: false,
      userInfo: {},
      submitLoading: false,
      gradeList: [],
      clear: {
        level: 2,
        year: "2022",
      },
      isIndeterminate: false,
      allCheck: [],
      reverse: false,
      cancleAll: false,
      classList: [],
      checkedClass: [],
      older: [],
      classLevelList: [],
      yearOptions: [...yearList.reverse()],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("jzjx-user-message"));
    this.search.schoolId = this.userInfo.content.schoolId;
    this.getclasslevel();
    this.getData();
    this.labels = [...this.labelList];
    this.checkList = [...this.labelList];
  },
  methods: {
    addLevel() {
      this.classLevelData.push({ name: "" });
    },
    delLevel(index) {
      this.classLevelData.splice(index, 1);
    },
    saveClassLevel() {
      this.$confirm("是否修改班级层次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let arr = this.classLevelData.map((item) => item.name);
            await saveClasslevel({
              name: arr.filter((item) => item).toString(),
            });
            this.$message.success("提交成功");
            this.dialogVisible = false;
            this.getData();
            this.getclasslevel();
          } catch {
            //
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getclasslevel() {
      const res = await classlevel();
      this.classLevelData = res.data.data.length > 0 ? res.data.data : [{}];
      this.classLevelList = res.data.data.length > 0 ? res.data.data : [{}];
    },
    getGradeName(gradeId) {
      const data = this.gradeList.find((item) => item.id == gradeId);
      if (data) {
        return data.year;
      } else {
        return "-";
      }
    },
    levelChange() {
      this.search.gradeId = "";
      this.getData();
    },
    async getClearClassNum() {
      const res = await classlistbylevelandyear({ ...this.clear });
      this.classList = res.data.data;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.allCheck = checkedCount === this.classList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classList.length;
    },
    allCheckChange() {
      this.checkedClass = this.classList.map((item) => item.name);
    },
    reverseChange() {
      const subjectIds = this.classList.map((item) => item.subjectId);
      const reverseArr = subjectIds.filter(
        (item) => !this.checkedClass.includes(item)
      );
      this.checkedClass = reverseArr;
    },
    cancleAllChange() {
      this.checkedClass = [];
    },
    clearStudent() {
      this.dialogVisible = true;
      this.title = "清除";
    },
    async exportExcel() {
      const res = await exportstudent();
      fileDownloadByUrl(
        res.data.data.data.url,
        `${res.data.data.data.name}.xls`
      );
    },
    importExcel() {
      this.dialogVisible = true;
      this.title = "批量导入";
      this.type = 1;
    },
    add() {
      this.dialogVisible = true;
      this.title = "新增";
      this.getClearClassNum();
    },
    batchEdit() {
      this.dialogVisible = true;
      this.title = "批量修改";
      this.type = 2;
    },
    edit(row) {
      this.getClearClassNum();
      this.dialogVisible = true;
      this.title = "编辑";
      this.form = { ...row };
      this.form.year = String(row.year);
    },
    handleRemove() {},
    handleOnSuccess(response) {
      if (response.code === 1) {
        this.$message.error("导入失败");
        window.open(response.msg, "_blank");
        this.getData();
      } else {
        this.$message.success("导入成功");
        this.getData();
      }
      this.dialogVisible = false;

      this.submitLoading = false;
    },
    importSubmit() {
      this.$refs.upload.submit();
      this.submitLoading = true;
    },
    deleteStudent() {
      removeStudent({
        ids: this.multipleSelection.map((item) => item.id).toString(),
      }).then(() => {
        this.getData();
      });
      this.showTab = false;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.title === "新增") {
            try {
              this.submitLoading = true;
              await addschoolgradeclass({ ...this.form });
              this.dialogVisible = false;
              this.$message.success("提交成功");
              this.submitLoading = false;
              this.form = {};
            } catch {
              this.submitLoading = false;
            }
          } else if (this.title === "编辑") {
            try {
              this.submitLoading = true;
              await editSchoolgradeclass({ ...this.form });
              this.dialogVisible = false;
              this.$message.success("提交成功");
              this.submitLoading = false;
              this.form = {};
              this.getData();
            } catch {
              this.submitLoading = false;
            }
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    checkListChange() {
      this.labels = this.checkList.sort((a, b) => a.sort - b.sort);
    },
    del(row) {
      this.$confirm("此操作将永久删除该学生信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await delStudentNewExam({ id: row.id });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData();
          } catch {
            this.$message({
              type: "info",
              message: "删除失败",
            });
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    hidden() {
      this.showTab = false;
      this.multipleSelection = [];
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    async getClassList() {
      if (this.search.year) {
        const res = await getClassNum({
          year: this.search.year,
          level: this.search.level,
        });
        this.filterOptions[2].options = res.data.data;
      } else {
        this.filterOptions[2].options = [];
      }
    },
    getFilterValue(val) {
      this.search = { ...val };
      this.getData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.showTab = true;
      }
    },
    async getData() {
      this.loading = true;
      const res = await getClassNumPage({ ...this.search, ...this.page });
      this.loading = false;
      this.tableData = res.data.data.records;
      this.total = res.data.data.total;
    },
    classLevel() {
      this.title = "班级层次";
      this.dialogVisible = true;
      this.getclasslevel();
    },
    clearAll() {
      this.submitLoading = true;
      this.$confirm("此操作将永久删除选中班级学生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = {
            classNum: this.checkedClass.toString(),
            ...this.clear,
          };
          cleanStudent(params)
            .then(() => {
              this.getData();
              this.$message.success("删除成功");
              this.submitLoading = this.dialogVisible = false;
            })
            .catch(() => {
              this.submitLoading = this.dialogVisible = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.student {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .row {
        display: flex;
        align-items: center;
        .label {
          font-size: 14px;
          margin-right: 10px;
        }
        ::v-deep .el-select {
          width: 112px;
        }
      }
    }
  }
  .tableList {
    margin-bottom: 24px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}
.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}
::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
.batch {
  line-height: 24px;
  font-size: 14px;
  color: #101011;
  .template {
    margin-top: 24px;
    margin-bottom: 30px;
  }
}
::v-deep .el-upload-dragger {
  width: 100% !important;
}
::v-deep .el-upload {
  width: 100% !important;
}
.levelRow {
  margin-bottom: 20px;
}
.filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .row {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
}
.checkbox {
  margin-left: 45px;
}
.classList {
  ::v-deep .el-checkbox {
    width: 85px;
    margin: 10px 0;
  }
}
::v-deep .el-date-editor i {
  display: flex !important;
  align-items: center !important;
}
</style>
